import { IdToken, useAuth0 as useAuth0VendorHook } from '@auth0/auth0-react';
import Cookies from 'js-cookie';
import { ContextType, ReactElement, useCallback } from 'react';

import { ScenarioCookies, ApiIdentity } from '@hubcms/domain-auth';
import { AuthContext } from '@hubcms/data-access-auth';

export type Auth0DataConsumerRenderProps = ContextType<typeof AuthContext> & {
  getIdTokenClaims: () => Promise<IdToken | undefined>;
};
export type Auth0DataConsumerProps = {
  render: (props: Auth0DataConsumerRenderProps) => ReactElement;
};
export const Auth0DataConsumer = ({ render }: Auth0DataConsumerProps): ReactElement => {
  const { loginWithRedirect, logout: auth0logout, isAuthenticated, isLoading, user, getIdTokenClaims } = useAuth0VendorHook();
  const { nickname, email, sub } = user || {};
  const [givenName, familyName] = nickname?.split('.') || [];

  const login = useCallback(
    (returnUrl?: string) => {
      if (!isAuthenticated) {
        if (returnUrl) {
          Cookies.set(ScenarioCookies.LOGIN_ORIGIN, returnUrl);
        }
        loginWithRedirect();
      } else if (!isLoading && typeof window !== 'undefined') {
        window.location.href = returnUrl || '/';
      }
    },
    [isAuthenticated, loginWithRedirect, isLoading],
  );

  const logout = useCallback(
    (returnTo?: string) => {
      if (isAuthenticated) {
        Cookies.remove(ScenarioCookies.ID_TOKEN);
        auth0logout({
          logoutParams: {
            returnTo: window.location.href,
          },
        });
      } else if (typeof window !== 'undefined') {
        window.location.href = returnTo || '/';
      }
    },
    [isAuthenticated, auth0logout],
  );

  return render({
    isAuthenticated,
    login,
    logout,
    isLoading,
    user: user
      ? ({
          givenName,
          familyName,
          email,
          sub,
        } as ApiIdentity['user'])
      : null,
    getIdTokenClaims,
  });
};

import dynamic from 'next/dynamic';
import { FC, PropsWithChildren } from 'react';

import { AuthContext } from '@hubcms/data-access-auth';

import { Auth0DataConsumer } from './Auth0DataConsumer';
import { getAuth0RedirectURI } from './getAuth0RedirectURI';

const DynamicProvider = dynamic(() => import('@auth0/auth0-react').then(m => m.Auth0Provider), {
  loading: () => <>Loading auth...</>,
});

export const Auth0Provider: FC<PropsWithChildren> = ({ children }) => {
  return (
    <DynamicProvider
      domain="tps-news-cue.eu.auth0.com"
      clientId="W7mTvyT1DigzwBJ5INzn5fjG8d2vh89M"
      authorizationParams={{
        // eslint-disable-next-line camelcase
        redirect_uri: getAuth0RedirectURI(),
        audience: 'https://tps-news-cue.eu.auth0.com/api/v2/',
        scope: 'read:current_user update:current_user_metadata profile email',
      }}
    >
      <Auth0DataConsumer render={context => <AuthContext.Provider value={context}>{children}</AuthContext.Provider>} />
    </DynamicProvider>
  );
};

import getConfig from 'next/config';

import { Environments } from '../../domain/environments';

export const getAuth0RedirectURI = (): string => {
  const { publicRuntimeConfig } = getConfig();
  if (publicRuntimeConfig.MH_ENV && publicRuntimeConfig.MH_ENV !== Environments.LOCAL && publicRuntimeConfig.PILOT_HOSTNAME) {
    return `https://${publicRuntimeConfig.PILOT_HOSTNAME}/login-redirect`;
  }
  return 'http://localhost:4000/login-redirect';
};

export enum Environments {
  LOCAL = 'LOCAL',
  DEV = 'DEVTEST',
  TEST = 'TEST',
  PROD = 'PROD',
}
export enum MhEnvironments {
  test = 'test-',
  acc = 'test-cue-',
  prod = '',
}
